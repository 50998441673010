<template>
  <BaseModal name="andamento_docs" size="xl" title="🗂️ Documentos enviados">
    <div
      key="loading"
      v-if="loading"
      class="py-5 my-5 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"> </b-spinner>
    </div>
    <div v-if="!loading">
      <b-row class="Table-header mb-1">
        <b-col cols="2" md="1"> #ID </b-col>

        <b-col cols="2" class="d-none d-md-block">Modificado em </b-col>
        <b-col cols="5" md="3" class=""> Status </b-col>

        <b-col cols="3" class="d-none d-md-block"> Motivo </b-col>
        <b-col cols="5" md="3"> Ver arquivo </b-col>
      </b-row>

      <b-row class="Table-body" v-for="item in docs" :key="item.id">
        <b-col cols="2" md="1">#{{ item.id }}</b-col>

        <b-col cols="2" class="d-none d-md-block">{{
          item.updated_at | datetime
        }}</b-col>
        <b-col cols="5" md="3" class="" v-if="item.status == 'APPROVED'"
          >✔️ aprovado</b-col
        >
        <b-col cols="5" md="3" class="" v-if="item.status == 'REQUESTED'"
          >💡 em analise</b-col
        >
        <b-col cols="5" md="3" class="" v-if="item.status == 'DISAPPROVED'"
          >🔴 recusado</b-col
        >

        <b-col cols="3" class="d-none d-md-block">
          {{ item.pending_motive || "---" }}
        </b-col>
        <b-col cols="5" md="3">
          <a
            href="#"
            class="ver-arquivo"
            @click.prevent="downloadArquivo(item.path)"
            >Baixar Arquivo</a
          ></b-col
        >
      </b-row>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" @click="cancel"> Fechar </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import DownloadService from "@/services/resources/DownloadService";
const serviceDownload = DownloadService.build();

export default {
  name: "AndamentoDocs",
  data() {
    return {
      loading: false,
    };
  },

  props: {
    docs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    downloadArquivo(path) {
      this.loading = true;
      const ext = path.split(".").pop();
      let data = {
        url: path,
      };

      serviceDownload
        .blob(data)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `arquivo.${ext}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.ver-arquivo {
  font-weight: 600;
  color: #2a63ab;
  font-size: 13px;
  display: inline-block;
  padding: 5px;
}
</style>
