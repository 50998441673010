<template>
  <div class="container pt-4">
    <BaseHeader title="Minha conta" class="mb-5"></BaseHeader>
    <b-tabs
      class="mt-4"
      nav-class="TabHeader no-border"
      active-nav-item-class="TabHeader--active"
      :no-nav-style="true"
    >
      <b-tab
        :title="
          `Pessoal ${
            (user.documents.length > 1
            ? checkStatus(user.documents, 'APPROVED').length >= 2
            : checkStatus(user.documents, 'APPROVED').length)
              ? '✔️'
              : ''
          }`
        "
        active
      >
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
          <h5 class="my-3">Conta</h5>
          <b-row>
            <b-col class="foto-user" cols="12" md="6">
              <b-form-group label="Identificação Pessoal">
                <p
                  class="info-documento mb-2"
                  v-if="status_validation.status == 'INITIATED'"
                  data-anima="top"
                >
                  Para garantirmos a segurança e evitarmos fraudes, precisamos
                  que realize a identificação pessoal.
                </p>

                <div class="top-status">
                  <div
                    class="aprovacao APPROVED btn-2 mt-2 mb-3"
                    v-if="status_validation.status == 'APPROVED'"
                  >
                    <!-- v-if="user.document_status == 'APPROVED'" -->
                    <img src="@/assets/img/icons/info-prod-aprovado.svg" />
                    Identificação Pessoal Aprovada
                  </div>

                  <div
                    class="aprovacao btn-2 mt-2 mb-3"
                    v-else-if="status_validation.status == 'REQUESTED'"
                  >
                    <img src="@/assets/img/icons/info-prod.svg" />
                    Identificação Pessoal em Análise
                  </div>

                  <div
                    class="aprovacao DISAPPROVED btn-2 mt-2 mb-3"
                    v-else-if="status_validation.status == 'DISAPPROVED'"
                  >
                    <img src="@/assets/img/icons/info-prod-reprovado.svg" />
                    Identificação Pessoal Rejeitada
                  </div>
                </div>

                <!-- texto -->
                <p
                  class="info-documento"
                  v-if="status_validation.status == 'APPROVED'"
                >
                  Sua Identificação Pessoal passou pela análise interna e já
                  constam como aprovada.<br />
                  Você já pode adicionar seus dados financeiros
                  <router-link to="/extrato" class="link-aprovado"
                    >clicando aqui</router-link
                  >
                </p>
                <!-- {{ checkStatus(user.documents, "DISAPPROVED") }} -->
                <p
                  class="info-documento"
                  v-else-if="status_validation.status == 'REQUESTED'"
                >
                  Sua Identificação Pessoal foi enviada e está em processo de
                  análise interna. <br />Aguarde o processamento dos dados.
                </p>

                <p
                  class="info-documento"
                  v-else-if="status_validation.status == 'DISAPPROVED'"
                >
                  Sua Identificação Pessoal passou pela análise interna, porém
                  foi não foi aprovada.<br />
                  Motivo:
                  <b>{{ status_validation.observation }}</b>
                </p>
                <BaseButton
                  variant="secondary_outline"
                  class="mt-3 mb-2"
                  @click="openValidation"
                  v-if="
                    status_validation.status == 'INITIATED' ||
                      status_validation.status == 'DISAPPROVED'
                  "
                  >{{
                    status_validation.status == "DISAPPROVED"
                      ? "Nova verificação de identidade"
                      : "Iniciar verificação de identidade"
                  }}</BaseButton
                >
              </b-form-group>
            </b-col>

            <b-col class="foto-user" cols="12" md="6">
              <b-form-group label="Foto do perfil">
                <vue-upload-multiple-image
                  :multiple="false"
                  @upload-success="uploadImageSuccess"
                  @before-remove="beforeRemove"
                  @edit-image="editImage"
                  :data-images="help_foto"
                  idUpload="userImgUpdate"
                  editUpload="userImgEdit"
                  dragText=""
                  browseText="Procurar imagens"
                  :showPrimary="false"
                  :showEdit="false"
                ></vue-upload-multiple-image>
              </b-form-group>
            </b-col>
          </b-row>

          <h5 class="my-3">Dados Pessoais</h5>
          <form data-vv-scope="user">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Nome" label-for="name">
                  <b-form-input
                    id="name"
                    name="name"
                    v-model="user.name"
                    type="text"
                    placeholder="Escreva o seu nome"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.name')">
                    Por favor, insira o seu nome
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Telefone" label-for="phone">
                  <b-form-input
                    id="phone"
                    name="phone"
                    v-model="user.cellphone"
                    type="text"
                    placeholder="Seu telefone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.phone')">
                    Por favor, insira o seu telefone
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <!-- if (this.$store.getters.verifyEmail) { -->
                <b-form-group label="Endereço de E-mail" label-for="email">
                  <b-form-input
                    id="email"
                    name="email"
                    v-model="user.email"
                    type="text"
                    placeholder="Insira seu e-mail"
                    v-validate="'required|email'"
                    :disabled="$store.getters.verifyEmail"
                    :readonly="$store.getters.verifyEmail"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.email')">
                    Por favor, insira o seu endereço de e-mail.
                  </b-form-invalid-feedback>
                  <p
                    v-if="$store.getters.verifyEmail"
                    class="info-documento mt-2"
                  >
                    Este email já consta validado. Não é possível alterar
                  </p>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="CPF" label-for="cpf" id="campo-cpf">
                  <b-form-input
                    id="cpf"
                    name="cpf"
                    v-model="user.cpf_cnpj"
                    type="text"
                    placeholder="Digite o seu CPF"
                    v-validate="'required|cpf_cnpj'"
                    v-mask="['###.###.###-##']"
                    :disabled="
                      status_validation.status == 'APPROVED' &&
                      dados_user.cpf_cnpj
                        ? true
                        : false
                    "
                    :readonly="
                      status_validation.status == 'APPROVED' &&
                      dados_user.cpf_cnpj
                        ? true
                        : false
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.cpf')">
                    Por favor, insira o número do seu CPF
                  </b-form-invalid-feedback>

                  <b-tooltip
                    v-if="
                      status_validation.status == 'APPROVED' && user.cpf_cnpj
                    "
                    target="campo-cpf"
                    title=" Sua Identificação Pessoal foi validada. Não é possível alterar."
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="RG" label-for="rg" id="campo-rg">
                  <b-form-input
                    id="rg"
                    name="rg"
                    v-model="user.rg"
                    type="text"
                    placeholder="Digite o seu RG"
                    v-validate="'required'"
                    v-mask="['#############']"
                    :disabled="
                      status_validation.status == 'APPROVED' && dados_user.rg
                        ? true
                        : false
                    "
                    :readonly="
                      status_validation.status == 'APPROVED' && dados_user.rg
                        ? true
                        : false
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.rg')">
                    Por favor, insira o número do seu RG
                  </b-form-invalid-feedback>

                  <b-tooltip
                    v-if="status_validation.status == 'APPROVED' && user.rg"
                    target="campo-rg"
                    title=" Sua Identificação Pessoal foi validada. Não é possível alterar."
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <h5 class="my-3">Dados do Endereço</h5>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="CEP" label-for="cep">
                  <b-form-input
                    id="cep"
                    v-model="address_user.zip_code"
                    placeholder="Digite o seu CEP"
                    v-mask="['#####-###']"
                    type="text"
                    @keyup="preencherCep('usuario')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Logradouro" label-for="rua">
                  <b-form-input
                    id="rua"
                    v-model="address_user.street"
                    type="text"
                    placeholder="Logradouro"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Número" label-for="num">
                  <b-form-input
                    id="num"
                    v-model="address_user.number"
                    type="text"
                    v-mask="['########']"
                    placeholder="Número da residencia"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Bairro" label-for="bairro">
                  <b-form-input
                    id="bairro"
                    v-model="address_user.neighborhood"
                    type="text"
                    placeholder="Digite o seu Bairro"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Cidade" label-for="cidade">
                  <b-form-input
                    id="cidade"
                    v-model="address_user.city"
                    type="text"
                    placeholder="Digite o seu Cidade"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Estado" label-for="estado">
                  <b-form-input
                    id="estado"
                    v-model="address_user.state"
                    type="text"
                    placeholder="Digite o seu Estado"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <h5 class="my-3">Avançado</h5>
            <div>
              <BaseButton variant="info3" @click="openResetSenha"
                >Alterar minha senha</BaseButton
              >
            </div>

            <b-row>
              <b-col cols="12" class="d-flex justify-content-end mt-3">
                <BaseButton variant="secondary" @click="onSubmit('user')"
                  >Salvar Alterações</BaseButton
                >
              </b-col>
            </b-row>
          </form>
        </b-container>
      </b-tab>
      <b-tab
        :title="
          `Empresarial ${
            checkStatus(company.documents, 'APPROVED').length ? '✔️' : ''
          }`
        "
      >
        <b-container fluid class="mt-1 wrapper container-user" v-if="!loading">
          <h5 class="my-3">Dados da Empresa</h5>
          <form data-vv-scope="company">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Razão Social" label-for="name_company">
                  <b-form-input
                    id="name_company"
                    name="name_company"
                    v-model="company.name"
                    type="text"
                    placeholder="Escreva a Razão Social"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('company.name_company')"
                  >
                    Por favor, insira o nome da empresa
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Nome Fantasia" label-for="fantasy_name">
                  <b-form-input
                    id="fantasy_name"
                    name="fantasy_name"
                    v-model="company.fantasy_name"
                    type="text"
                    placeholder="Nome Fantasia da empresa"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="CNPJ" label-for="cnpj" id="campo-cnpj">
                  <b-form-input
                    id="cnpj"
                    name="cnpj"
                    v-model="company.cnpj"
                    placeholder="Digite o CNPJ da empresa"
                    v-mask="['##.###.###/####-##']"
                    v-validate="'required'"
                    :disabled="
                      checkStatus(company.documents, 'APPROVED').length
                        ? true
                        : false
                    "
                    :readonly="
                      checkStatus(company.documents, 'APPROVED').length
                        ? true
                        : false
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('company.cnpj')">
                    Por favor, insira o CNPJ da empresa
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-tooltip
                  v-if="checkStatus(company.documents, 'APPROVED').length"
                  target="campo-cnpj"
                  title=" Contrato social consta validados. Não é possível alterar."
                />
              </b-col>

              <b-col cols="12" md="6" class="mt-4">
                <b-form-group
                  label="Contrato Social"
                  label-for="contract"
                  v-if="!company.documents.length"
                  data-anima="top"
                >
                  <b-form-file
                    id="contract"
                    name="contract"
                    plain
                    accept=".pdf"
                    v-model="contract_user"
                    v-validate="'required'"
                    @change="changeContract = true"
                  ></b-form-file>

                  <b-form-invalid-feedback
                    :state="!errors.has('company.contract')"
                  >
                    Por favor, insira o Contrato social da empresa
                  </b-form-invalid-feedback>
                  <p class="info-documento">
                    Para sua segurança, insira o Contrato Social da empresa.
                    Assim sua conta sempre estará protegida. Arquivo deve ser
                    adicionado em PDF.
                  </p>
                </b-form-group>

                <b-form-group
                  label="Contrato Social"
                  label-for="contract"
                  v-show="company.documents.length"
                >
                  <div class="top-status">
                    <div
                      class="aprovacao APPROVED btn-2 mt-3 mb-3"
                      v-if="checkStatus(company.documents, 'APPROVED').length"
                    >
                      <img src="@/assets/img/icons/info-prod-aprovado.svg" />
                      Contrato Social Aprovado
                    </div>

                    <div
                      class="aprovacao btn-2 mt-3 mb-3"
                      v-else-if="
                        checkStatus(company.documents, 'REQUESTED').length
                      "
                    >
                      <img src="@/assets/img/icons/info-prod.svg" />
                      Contrato Social em Análise
                    </div>

                    <div
                      class="aprovacao DISAPPROVED btn-2 mt-3 mb-3"
                      v-else-if="
                        checkStatus(company.documents, 'DISAPPROVED').length
                      "
                    >
                      <img src="@/assets/img/icons/info-prod-reprovado.svg" />
                      Contrato Social Rejeitado
                    </div>

                    <!-- ver arquivo -->
                    <a
                      v-if="
                        (checkStatus(company.documents, 'DISAPPROVED').length ||
                          checkStatus(company.documents, 'REQUESTED').length) &&
                          !checkStatus(company.documents, 'APPROVED').length
                      "
                      href="#"
                      class="link-aprovado ver-arquivo"
                      @click.prevent="openAndamentoDocs(company.documents)"
                      >Ver arquivos</a
                    >
                    <a
                      v-if="
                        (checkStatus(company.documents, 'DISAPPROVED').length ||
                          checkStatus(company.documents, 'REQUESTED').length) &&
                          !checkStatus(company.documents, 'APPROVED').length
                      "
                      href="#"
                      class="link-aprovado ver-arquivo"
                      @click.prevent="company.documents = []"
                      >Editar arquivo</a
                    >
                  </div>

                  <!-- texto -->
                  <p
                    class="info-documento"
                    v-if="checkStatus(company.documents, 'APPROVED').length"
                  >
                    Seu contrato social passou pela análise interna e já consta
                    como aprovado.<br />
                    Você já pode adicionar seus dados financeiros
                    <router-link to="/extrato" class="link-aprovado"
                      >clicando aqui</router-link
                    >
                  </p>
                  <p
                    class="info-documento"
                    v-else-if="
                      checkStatus(company.documents, 'REQUESTED').length
                    "
                  >
                    Seu contrato social foi enviado e está em processo de
                    análise interna. <br />Aguarde o processamento dos dados.
                  </p>
                  <p
                    class="info-documento"
                    v-else-if="
                      checkStatus(company.documents, 'DISAPPROVED').length
                    "
                  >
                    Seu contrato social passou pela análise interna, porém não
                    foi aprovado<br />
                    Motivo:
                    <b>{{
                      checkStatus(company.documents, "DISAPPROVED")[
                        checkStatus(company.documents, "DISAPPROVED").length - 1
                      ].pending_motive
                    }}</b>
                  </p>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" class="email-company">
                <b-form-group label="Telefone" label-for="phone_company">
                  <b-form-input
                    id="phone_company"
                    name="phone_company"
                    v-model="company.telephone"
                    type="text"
                    placeholder="Telefone da Empresa"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('company.phone_company')"
                  >
                    Por favor, insira o telefone da empresa
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- if (this.$store.getters.verifyEmail) { -->
              </b-col>
            </b-row>

            <b-row> </b-row>

            <h5 class="my-3">Dados de Suporte</h5>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Endereço de E-mail" label-for="email">
                  <b-form-input
                    id="email"
                    name="email"
                    v-model="company.email"
                    type="text"
                    placeholder="Insira o e-mail da empresa"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('company.email')"
                  >
                    Por favor, insira o endereço de e-mail.
                  </b-form-invalid-feedback>
                  <p v-if="company.email" class="info-documento mt-2">
                    Este email será usado para relacionamento e suporte com o
                    cliente.
                  </p>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="Telefone de suporte"
                  label-for="support_telephone"
                >
                  <b-form-input
                    id="support_telephone"
                    name="support_telephone"
                    v-model="company.support_telephone"
                    type="text"
                    placeholder="Telefone de suporte"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('company.support_telephone')"
                  >
                    Por favor, insira o telefone de suporte
                  </b-form-invalid-feedback>
                  <p
                    v-if="company.support_telephone"
                    class="info-documento mt-2"
                  >
                    Este email será usado para relacionamento e suporte com o
                    cliente.
                  </p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row> </b-row>

            <h5 class="my-3">Dados do Endereço</h5>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="CEP da Empresa" label-for="cep_company">
                  <b-form-input
                    id="cep_company"
                    v-model="company.zip_code"
                    placeholder="Digite o CEP da empresa"
                    v-mask="['#####-###']"
                    type="text"
                    @keyup="preencherCep('empresa')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Logradouro" label-for="rua_company">
                  <b-form-input
                    id="rua_company"
                    v-model="company.street"
                    type="text"
                    placeholder="Logradouro da empresa"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Número" label-for="num_company">
                  <b-form-input
                    id="num_company"
                    v-model="company.number"
                    type="text"
                    v-mask="['########']"
                    placeholder="Número da empresa"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Bairro" label-for="bairro_company">
                  <b-form-input
                    id="bairro_company"
                    v-model="company.neighborhood"
                    type="text"
                    placeholder="Digite o Bairro da empresa"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Cidade" label-for="cidade_company">
                  <b-form-input
                    id="cidade_company"
                    v-model="company.city"
                    type="text"
                    placeholder="Digite a Cidade da empresa"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Estado" label-for="estado_company">
                  <b-form-input
                    id="estado_company"
                    v-model="company.state"
                    type="text"
                    placeholder="Digite o Estado da empresa"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" class="d-flex justify-content-end mt-3">
                <BaseButton variant="secondary" @click="onSubmit('company')"
                  >Salvar Alterações</BaseButton
                >
              </b-col>
            </b-row>
          </form>
        </b-container>
      </b-tab>
      <b-tab title="Contas Corrente" @click="acessarContaCorrente"> </b-tab>
      <div
        key="loading"
        v-if="loading"
        class="py-5 my-5 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"> </b-spinner>
        <p class="ml-4 info-documento">Estamos carregando sua solicitação</p>
      </div>
    </b-tabs>
    <ResetPassword :user_id="user.id" />
    <AndamentoDocs :docs="andamento" />
    <ModalValidation @close="initDados" />
    <!--<CallEvaluation /> -->
  </div>
</template>
<script>
import { EventBus } from "@/main.js";

import VueUploadMultipleImage from "vue-upload-multiple-image";
import ResetPassword from "@/components/Seller/MyAccount/modals/reset_password.vue";
import AndamentoDocs from "@/components/Seller/MyAccount/modals/AndamentoDocs.vue";

import UserService from "@/services/resources/UserService";
const serviceUser = UserService.build();

import ValidationService from "@/services/resources/ValidationService";
const serviceValidation = ValidationService.build();

export default {
  data() {
    return {
      dados_user: {},
      loading: false,
      submit: false,
      status_validation: {
        status: "",
        observation: "",
      },
      foto_user: [],
      help_foto: [],
      document_user: [],
      contract_user: null,
      changeContract: false,

      troca_senha: false,
      andamento: [],
      user: {
        id: -1,
        name: "",
        cellphone: "",
        email: "",
        cpf_cnpj: "",
        rg: "",
        image: [],
        documents: [
          {
            status: "REQUESTED",
          },
        ],
        old_password: "",
        new_password: "",
      },
      address_user: {
        zip_code: "00000-000",
        street: "",
        number: "",
        neighborhood: "",
        city: "",
        state: "",
      },

      company: {
        documents: [
          {
            status: "REQUESTED",
          },
        ],
        name: "",
        fantasy_name: "",
        telephone: "",
        support_telephone: "",
        email: "",
        cnpj: "",
        zip_code: "00000-000",
        street: "",
        number: "",
        neighborhood: "",
        city: "",
        state: "",
      },
    };
  },
  components: {
    VueUploadMultipleImage,
    ResetPassword,
    AndamentoDocs,
  },

  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
  },
  created() {
    if (this.$store.getters.setPanel) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    openValidation() {
      this.$bvModal.show("identity-validation");
    },
    acessarContaCorrente() {
      this.$router.push("extrato/?withdraw=true");
    },
    openAndamentoDocs(docs) {
      this.andamento = docs;
      this.$bvModal.show("andamento_docs");
    },
    checkStatus(docs, status) {
      if (docs.length) {
        return docs.filter((item) => item.status == status);
      } else return [];
    },
    openResetSenha() {
      this.$bvModal.show("reset_password");
    },

    // foto usuario
    uploadImageSuccess(formData, index, fileList) {
      this.foto_user = fileList;
      this.help_foto = fileList;
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("Remover imagem?");
      if (r == true) {
        done();
        this.foto_user.splice(index, 1);
        this.help_foto.splice(index, 1);
      }
    },
    editImage(formData, index, fileList) {
      this.foto_user = fileList;
      this.help_foto = fileList;
    },

    // documento usuario
    uploadDocumentSuccess(formData, index, fileList) {
      this.document_user = fileList;
    },
    beforeRemoveDocument(index, done, fileList) {
      var r = confirm("Remover documento?");
      if (r == true) {
        done();
        this.document_user.splice(index, 1);
      }
    },
    editDocument(formData, index, fileList) {
      this.document_user = fileList;
    },

    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },

    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);
      return formData;
    },
    onSubmit(tipo) {
      this.submit = true;
      this.loading = true;

      let validaItem = true;
      if (tipo == "user" && !this.user.documents.length) {
        validaItem = this.document_user ? true : false;
      } else if (tipo == "company" && !this.company.documents.length) {
        validaItem = this.contract_user ? true : false;
      }

      this.$validator.validateAll(tipo).then(async (result) => {
        if (result && validaItem) {
          let data = {
            id: this.user.id,
            company: {},
          };
          if (tipo == "user") {
            data = this.user;
            data.address = this.address_user;
            data.image = this.foto_user[0];
            data.documents = this.document_user;

            delete data.company;
          } else if (tipo == "company") {
            data.company = this.company;
            if (this.changeContract) {
              data.company.documents = [this.contract_user];
            } else {
              data.company.documents = [];
            }
          }

          data._method = "PUT";
          data = this.jsonToFormData(data);

          serviceUser
            .create(data, this.user.id)
            .then(() => {
              // resetando coockie
              this.initDados();

              if (tipo == "user") {
                this.user.documents = [{ status: "REQUESTED" }];
              } else if (tipo == "company") {
                this.company.documents = [{ status: "REQUESTED" }];
              }

              this.$bvToast.toast("As informações foram salvas com sucesso!", {
                title: "Dados do Perfil",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch((error) => {
              if (tipo == "company") {
                this.company.contract = null;
              }
              console.log(error);
            })
            .finally(() => {
              this.loading = false;
              this.submit = false;
            });
        } else {
          this.loading = false;
          this.$bvToast.toast("Valide os campos obrigatórios", {
            title: "Dados do Perfil",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      });
    },

    preencherCep(tipo) {
      let cep = "";
      if (tipo == "empresa") {
        cep = this.company.zip_code.replace(/\D/g, "");
      } else if (tipo == "usuario") {
        cep = this.address_user.zip_code.replace(/\D/g, "");
      }
      if (cep.length === 8) {
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((response) => response.json())
          .then((response) => {
            if (tipo == "empresa") {
              this.company.street = response.logradouro;
              this.company.neighborhood = response.bairro;
              this.company.city = response.localidade;
              this.company.state = response.uf;
              if (document.querySelector("#num_company")) {
                document.querySelector("#num_company").focus();
              }
            } else {
              this.address_user.street = response.logradouro;
              this.address_user.neighborhood = response.bairro;
              this.address_user.city = response.localidade;
              this.address_user.state = response.uf;
              if (document.querySelector("#num")) {
                document.querySelector("#num").focus();
              }
            }
          });
      }
    },

    getStatusValidation(id) {
      let data = {
        id: id,
      };
      serviceValidation
        .read(data)
        .then((response) => {
          this.status_validation = response;
          if (!this.status_validation.status) {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setDados() {
      this.getStatusValidation(this.dados_user.id);
      this.user = {
        id: this.dados_user.id,
        name: this.dados_user.name,
        cellphone: this.dados_user.cellphone,
        rg: this.dados_user.rg,
        email: this.dados_user.email,
        cpf_cnpj: this.dados_user.cpf_cnpj,
        image: this.dados_user.image,
        documents: this.dados_user.documents || [],
      };

      this.document_user = this.user.documents;

      if (this.user.image) {
        this.help_foto = [
          {
            path: this.user.image,
          },
        ];
      }

      this.address_user = Object.assign({}, this.dados_user.address);

      let dados_company = Object.assign({}, this.dados_user.company);
      this.company = {
        contract: dados_company.contract,
        name: dados_company.name,
        fantasy_name: dados_company.fantasy_name,
        telephone: dados_company.telephone,
        support_telephone: dados_company.support_telephone,
        cnpj: dados_company.cnpj,
        email: dados_company.email,
        zip_code: dados_company.zip_code,
        street: dados_company.street,
        number: dados_company.number,
        neighborhood: dados_company.neighborhood,
        city: dados_company.city,
        state: dados_company.state,
        documents: dados_company.documents || [],
      };

      this.loading = false;
    },

    initDados() {
      this.loading = true;

      this.$store
        .dispatch("userRequest")
        .then((response) => {
          this.dados_user = Object.assign({}, response);
        })
        .then(() => {
          this.setDados();
        })
        .catch((err) => {
          console.log(err);
          this.dados_user = Object.assign({}, this.$store.state.user.user);
          this.setDados();
        });
    },
  },
  mounted() {
    this.initDados();
  },
};
</script>

<style scoped>
.info-checkbox {
  margin: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-weight: 500;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: #002363;
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
}

.info-documento {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}

.info-documento + .info-documento {
  margin-top: 5px;
}
.btn-2 {
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
}
.aprovacao {
  display: flex;
  align-items: center;
}
.aprovacao img {
  margin-right: 10px;
}
.DISAPPROVED {
  background: rgba(255, 12, 55, 0.1);
  color: #ff0c37;
}
.APPROVED {
  background: rgba(0, 33, 99, 0.1);
  color: #002363;
}
.link-aprovado {
  font-weight: 600;
  color: #2a63ab;
}
.ver-arquivo {
  font-size: 13px;
  display: inline-block;
  padding: 5px;
}

.top-status {
  display: flex;
  align-items: center;
  gap: 30px;
}

.email-company {
  margin-top: -75px;
}
@media screen and (max-width: 768px) {
  .top-status {
    display: block;
  }
  .ver-arquivo {
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .email-company {
    margin-top: initial;
  }
}
</style>
