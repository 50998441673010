<template>
  <BaseModal
    name="reset_password"
    size="md"
    title="🔒 Alterar minha senha"
    @shown="openModal"
    @hidden="clear"
  >
    <b-overlay
      :show="loading"
      :disable="loading"
      rounded
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      class="d-block pt-2 pb-2"
    >
      <b-form>
        <!-- <div class="pt-2 pb-2"> -->
        <b-form-group label="Senha atual" label-for="password">
          <b-form-input
            id="password"
            name="password"
            type="password"
            v-model="user.old_password"
            v-validate="'required'"
          ></b-form-input>
          <span
            v-if="user.old_password"
            class="mostrar-senha"
            ref="mostrar_senha"
            @click="mostrarSenha('password')"
            >Mostrar</span
          >
          <b-form-invalid-feedback :state="!(errors.has('password') && submit)">
            Por favor, insira sua senha atual
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Senha nova" label-for="new_password" class="mt-3">
          <b-form-input
            id="new_password"
            name="new_password"
            type="password"
            v-validate="'required|min:8'"
            v-model="user.new_password"
          ></b-form-input>
          <span
            v-if="user.new_password"
            class="mostrar-senha"
            ref="mostrar_nova_senha"
            @click="mostrarSenha('new_password')"
            >Mostrar</span
          >
          <b-form-invalid-feedback
            :state="!(errors.has('new_password') && submit)"
          >
            Por favor, insira sua senha com no mínimo 8 caracteres.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-overlay>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-3" @click="cancel">
        Cancelar
      </BaseButton>
      <b-overlay
        :show="loading"
        :disable="loading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
      >
        <BaseButton variant="info3" @click="onSubmit"> Salvar senha</BaseButton>
      </b-overlay>
    </template>
  </BaseModal>
</template>
<script>
import UserService from "@/services/resources/UserService";
const serviceUser = UserService.build();

export default {
  props: {
    user_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showPassword: false,
      user: {
        id: -1,
        old_password: "",
        new_password: "",
      },
      loading: false,
      submit: false,
    };
  },
  methods: {
    mostrarSenha(seletor) {
      const input = document.querySelector(`#${seletor}`);
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        input.setAttribute("type", "text");
        if (seletor == "password") {
          this.$refs.mostrar_senha.innerText = "Ocultar";
        } else {
          this.$refs.mostrar_nova_senha.innerText = "Ocultar";
        }
      } else {
        input.setAttribute("type", "password");
        if (seletor == "password") {
          this.$refs.mostrar_senha.innerText = "Mostrar";
        } else {
          this.$refs.mostrar_nova_senha.innerText = "Mostrar";
        }
      }
    },
    onSubmit() {
      this.loading = true;
      this.submit = true;

      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let data = this.user;

          serviceUser
            .update(data)
            .then(() => {
              this.$bvToast.toast("Nova senha salva com sucesso!", {
                title: "Alterar senha",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.clear();
              this.loading = false;
              this.submit = false;
              this.$bvModal.hide("reset_password");
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.submit = false;
            });
        } else {
          this.loading = false;
          this.$bvToast.toast("Preecha os dados corretamente", {
            title: "Alterar senha",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      });
    },
    clear() {
      this.user = {
        id: -1,
        old_password: "",
        new_password: "",
      };
    },
    openModal() {
      this.user.id = this.user_id;
    },
  },
  mounted() {
    this.user.id = this.id;
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}
</style>
